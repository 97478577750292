export const b2cPolicies = {
    names: {
        signUp: 'b2c_1_signup_en'
    },
    authorities: {
        signUp: {
            authority: 'https://wsibb2cbat.b2clogin.com/wsibdev1.onmicrosoft.com/B2C_1_signup_en'
            //authority: ''

        },
        resetPassword: {
            authority: 'https://wsibb2cbat.b2clogin.com/wsibdev1.onmicrosoft.com/B2C_1_forgot_password_en'
        }
    },
    //authorityDomain: 'wsibdev1.b2clogin.com'
    authorityDomain: 'wsibb2cbat.b2clogin.com'
};

export const msalConfig = {
        auth: {
            clientId: 'cecdc5e2-47a8-4f31-9d72-c8a636f0ce05',
            authority:b2cPolicies.authorities.signUp.authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            redirectUri: 'https://bat.ospc.wsib.ca',
            //postLogoutRedirectUri: '/'
        },
        resetPassword: {
            clientId: 'cecdc5e2-47a8-4f31-9d72-c8a636f0ce05',
            authority: b2cPolicies.authorities.resetPassword.authority,
            knownAuthorities: [b2cPolicies.authorityDomain],
            redirectUri: 'https://bat.ospc.wsib.ca'
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false
        }
        
};

const secureLoginConfig = {
    url: 'https://loginbat.lfb.wsib.ca/login/createprofile?onStatus404=true{AND}app=myClaims{AND}lang=en'
}

export const secureLoginConfigURL = secureLoginConfig.url.toString().replace(/{AND}/g, '&');

export const nativeAppConfig = {
    callbackUrl: 'comdionaselfservice://auth/loggedOut'
}