function App() {
    return (
      <p></p>
      /*
      <BrowserRouter>
        <Routes>
          <Route path="/uaa/login/callback/microsoftb2c" element = {<IdpCallbackComponent />}/>
        </Routes>
      </BrowserRouter>
      */
    );
}

export default App;
