import React from "react";
import ReactDOM from "react-dom/client";

import { MsalProvider } from "@azure/msal-react";

import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { msalConfig, secureLoginConfigURL, nativeAppConfig } from "./config"
import App from "./app/App.js";

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (event?.error?.message && event?.error?.message.search('AADB2C90118') !== -1) {
        window.alert('test forgot password');
        console.log('test forgot password');
        msalInstance.loginRedirect(msalConfig.resetPassword.authority);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
  </React.StrictMode>
);

